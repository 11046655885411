<template>
  <div class="patient-files">
    <div class="files-menu">
      <el-card class="box-card" shadow="never" :body-style="{ padding: 0}">
        <a href="#part1" class="menu-item">基线信息</a>
        <a href="#part2" class="menu-item">植入信息</a>
        <!-- <a href="#part3" class="menu-item">诊断证明书</a> -->
        <a href="#part4" class="menu-item">随访记录</a>
        <a href="#part5" class="menu-item">心理量表</a>
      </el-card>
    </div>
    <el-card class="content-card">
      <el-scrollbar style="height: 100%">
        <div class="files-container">
          <div id="part1">
            <comp-part title="基线信息">
            <base-line-info :list="baseLineInfoList" @editData="editData" @cancel="cancel"></base-line-info>
          </comp-part>
          </div>
          <div id="part2">
            <comp-part title="植入信息">
              <div class="content-part">
                <div class="content-title">
                  <div class="label">
                    1、CIED植入信息
                    <div style="display: inline-block">
                      <i class="iconfontblack" type="text" size="mini" @click="isEditImplantInfo = true">&#xe600;</i>
                      <el-button class="edit-btn" v-if="isEditImplantInfo" type="text" size="mini" @click="handleSave(1)">保存</el-button>
                      <el-button class="edit-btn" v-if="isEditImplantInfo" type="text" size="mini" @click="handleCancel(1)">取消</el-button>
                    </div>
                  </div>
                </div>
                <div class="content-form">
                  <el-form :rules="requireItem" ref="requireItem" style="margin-top: 15px" :model="implantInfo" label-width="80px">
                    <el-row>
                      <el-col :span="12">
                        <el-form-item label="植入日期" prop="implantDate">
                          <el-date-picker
                            v-model="implantInfo.implantDate"
                            :disabled="true"
                            class="fix-form__width"
                            type="date"
                            format="yyyy-MM-dd"
                            value-format="timestamp"
                            placeholder="选择日期">
                          </el-date-picker>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item label="植入医院">
                          <el-input :disabled="!isEditImplantInfo" v-model="implantInfo.hospitalName" class="fix-form__width"></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item label="术者" prop="operator">
                          <el-input :disabled="!isEditImplantInfo" v-model="implantInfo.operator" class="fix-form__width"></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                </div>
              </div>
              <!--    植入部位   -->
              <div class="content-part">
                <div class="content-title">
                  <div class="label">
                    2、CIED植入部位
                    <div style="display: inline-block">
                      <i class="iconfontblack" type="text" size="mini" @click="isEditImplantPosition = true">&#xe600;</i>
                      <el-button class="edit-btn" v-if="isEditImplantPosition" type="text" size="mini" @click="handleSave(2)">保存</el-button>
                      <el-button class="edit-btn" v-if="isEditImplantPosition" type="text" size="mini" @click="handleCancel(2)">取消</el-button>
                    </div>
                  </div>
                </div>
                <div class="content-form">
                  <el-form style="margin-top: 15px">
                    <el-form-item>
                      <el-radio-group :disabled="!isEditImplantPosition" v-model="implantPosition">
                        <el-radio :label="1">左侧</el-radio>
                        <el-radio :label="2">右侧</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-form>
                </div>
              </div>
              <!--    适应证    -->
              <div class="content-part part1">
                <div class="content-title">
                  <div class="label">
                    3、适应证
                    <div style="display: inline-block">
                      <i class="iconfontblack" type="text" size="mini" @click="isEditIndications = true">&#xe600;</i>
                      <el-button class="edit-btn" v-if="isEditIndications" type="text" size="mini" @click="handleSave(3)">保存</el-button>
                      <el-button class="edit-btn" v-if="isEditIndications" type="text" size="mini" @click="handleCancel(3)">取消</el-button>
                    </div>
                    </div>
                </div>
                <div class="content-form">
                  <el-form :model="indications">
                    <el-form-item>
                      <el-checkbox-group :disabled="!isEditIndications" v-model="indications.indicationsSymptom" @change="changeAdaptation">
                        <el-checkbox class="el-check-pinpai" :label="0">
                          心动过缓
                        </el-checkbox>
                        <el-checkbox-group :disabled="!isEditIndications" v-if="indications.indicationsSymptom.indexOf(0) !== -1" v-model="indications.bradycardiaRadio" class="adaptation_content--1">
                          <el-checkbox :label="0">病态窦房结综合征</el-checkbox>
                          <el-checkbox :label="1">房室传导阻滞</el-checkbox>
                          <el-checkbox :label="2">房颤伴慢室率</el-checkbox>
                        </el-checkbox-group>
                        <el-checkbox class="el-check-pinpai" :label="1">
                          心力衰竭
                        </el-checkbox>
                        <br>
                        <el-checkbox class="el-check-pinpai" :label="2">
                          室性心律失常
                        </el-checkbox>
                        <el-radio-group :disabled="!isEditIndications" v-if="indications.indicationsSymptom.indexOf(2) !== -1" v-model="indications.arrhythmiaRadio" class="adaptation_content--1">
                          <el-radio :label="0">室性心动过速</el-radio>
                          <el-radio :label="1">心室颤动</el-radio>
                          <el-radio :label="2">室性期前收缩</el-radio>
                        </el-radio-group>
                        <el-checkbox :label="3">
                          其他
                        </el-checkbox>
                        <el-input
                          :disabled="indications.indicationsSymptom.indexOf(3) === -1 || !isEditIndications"
                          v-model="indications.otherIndications"
                          placeholder="请输入内容"
                          size="small"
                          style="display: block; width: 288px;"
                          minlength="2"
                          maxlength="20">
                        </el-input>
                      </el-checkbox-group>
                    </el-form-item>
                  </el-form>
                </div>
              </div>
              <!--    CIED类型  -->
              <div class="content-part">
                <div class="content-title">
                  <div class="label">
                    4、CIED类型
                    <div style="display: inline-block">
                      <i class="iconfontblack" type="text" size="mini" @click="isEditType = true">&#xe600;</i>
                      <el-button class="edit-btn" v-if="isEditType" type="text" size="mini" @click="handleSave(4)">保存</el-button>
                      <el-button class="edit-btn" v-if="isEditType" type="text" size="mini" @click="handleCancel(4)">取消</el-button>
                    </div>
                    </div>
                </div>
                <div class="content-form">
                  <el-form  style="margin-top: 15px" :model="ciedType">
                    <el-form-item>
                      <el-radio-group :disabled="!isEditType"  v-model="ciedType.ciedTypeRadio" @change="changeCiedType">
                        <el-radio :label="0">单腔起搏器</el-radio>
                        <el-radio :label="1">双腔起搏器</el-radio>
                        <el-radio :label="2">单腔ICD</el-radio>
                        <el-radio :label="3">双腔ICD</el-radio>
                        <el-radio :label="4">CRT-P</el-radio>
                        <el-radio :label="5">CRT-D</el-radio>
                        <br>
                        <el-radio :label="6">其他</el-radio>
                        <el-input :disabled="ciedType.ciedTypeRadio !== 6 || !isEditType" v-model="ciedType.otherCiedType" placeholder="请输入内容" size="small" style="display: inline-block; width: 228px"></el-input>
                      </el-radio-group>
                    </el-form-item>
                  </el-form>
                </div>
              </div>
              <!--    品牌      -->
              <div class="content-part">
                <div class="content-title">
                  <div class="label">
                    5、CIED品牌
                    <div style="display: inline-block">
                      <i class="iconfontblack" type="text" size="mini" @click="isEditBrand = true">&#xe600;</i>
                      <el-button class="edit-btn" v-if="isEditBrand" type="text" size="mini" @click="handleSave(5)">保存</el-button>
                      <el-button class="edit-btn" v-if="isEditBrand" type="text" size="mini" @click="handleCancel(5)">取消</el-button>
                    </div>
                    </div>
                </div>
                <div class="content-form">
                  <el-form  style="margin-top: 15px" :model="ciedBrand">
                    <el-form-item>
                      <el-radio-group :disabled="!isEditBrand"  class="ciedBrand-radio-group" v-model="ciedBrand.key" @change="changeCiedBrand">
                        <el-radio :label="0">美敦力
                          <el-select :disabled="ciedBrand.key !== 0 || !isEditBrand" filterable class="el-checkbox-input" allow-create v-model="ciedBrand.model" placeholder="请输入">
                            <el-option
                              v-for="i in ciedBrandSelect[0].children"
                              :key="i.code"
                              :label="i.title"
                              :value="i.code">
                            </el-option>
                          </el-select>
                        </el-radio>
                        <br>
                        <el-radio :label="1">雅培&nbsp;&nbsp;&nbsp;&nbsp;
                          <el-input
                            v-model="ciedBrand.yapeitext"
                            :disabled="ciedBrand.key !== 1 || !isEditBrand"
                            class="el-checkbox-input"
                            minlength="2"
                            maxlength="20">
                          </el-input>
                        </el-radio>
                        <br>
                        <el-radio :label="2">百力多
                          <el-input
                            v-model="ciedBrand.bailiduotext"
                            :disabled="ciedBrand.key !== 2 || !isEditBrand"
                            class="el-checkbox-input"
                            minlength="2"
                            maxlength="20">
                          </el-input>
                        </el-radio>
                        <br>
                        <el-radio :label="3">波科&nbsp;&nbsp;&nbsp;&nbsp;
                          <el-input
                            v-model="ciedBrand.boketext"
                            :disabled="ciedBrand.key !== 3 || !isEditBrand"
                            class="el-checkbox-input"
                            minlength="2"
                            maxlength="20">
                          </el-input>
                        </el-radio>
                        <br>
                        <el-radio :label="4">其他&nbsp;&nbsp;&nbsp;&nbsp;
                          <el-input
                            v-model="ciedBrand.qitatext"
                            :disabled="ciedBrand.key !== 4 || !isEditBrand"
                            class="el-checkbox-input"
                            minlength="2"
                            maxlength="20">
                          </el-input>
                        </el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-form>
                </div>
              </div>
              <!--    导线      -->
              <div class="content-part">
                <div class="content-title">
                  <div class="label">
                    6、导线
                    <div style="display: inline-block">
                      <i class="iconfontblack" type="text" size="mini" @click="isEditWireParam = true">&#xe600;</i>
                      <el-button class="edit-btn" v-if="isEditWireParam" type="text" size="mini" @click="handleSave(6)">保存</el-button>
                      <el-button class="edit-btn" v-if="isEditWireParam" type="text" size="mini" @click="handleCancel(6)">取消</el-button>
                    </div>
                    </div>
                </div>
                <div class="content-form">
                  <el-form :model="wireParam">
                    <el-form-item>
                      <el-checkbox :disabled="!isEditWireParam" v-model="wireParam.checkedAtrial" class="el-check-pinpai" label="心房导线" @change="changeLead"></el-checkbox>
                      <div class="lead-atrial" v-if="wireParam.checkedAtrial">
                        <div class="item-title">
                          植入部位:
                        </div>
                        <el-radio-group :disabled="!isEditWireParam" v-model="wireParam.atrialImplantPosition">
                          <el-radio :label="0">右心耳</el-radio>
                          <el-radio :label="1">右心房侧壁</el-radio>
                        </el-radio-group>
                        <br>
                        <div class="item-title">导线型号:</div>
                        <el-input :disabled="!isEditWireParam" size="small" placeholder="请输入导线型号" v-model="wireParam.atrialWireModel"></el-input>
                        <br>
                        <div class="item-title">
                          起搏阈值:
                        </div>
                        <el-row>
                          <el-col :span="12">
                            <el-input :disabled="!isEditWireParam" size="small" style="width: 85%;" placeholder="请输入内容" v-model="wireParam.atrialPacingThresholdV"></el-input>&nbsp;V@&nbsp;
                          </el-col>
                          <el-col :span="12">
                            <el-input :disabled="!isEditWireParam" size="small" style="width: 85%;" placeholder="请输入内容" v-model="wireParam.atrialPacingThresholdMs"></el-input>&nbsp;ms
                          </el-col>
                        </el-row>
                        <div class="item-title">
                          P波高度:
                        </div>
                        <el-row>
                          <el-col :span="12">
                            <el-input :disabled="!isEditWireParam" size="small" style="width: 85%;" placeholder="请输入内容" v-model="wireParam.atrialPWaveAltitude"></el-input>&nbsp;mV&nbsp;
                          </el-col>
                        </el-row>
                        <div class="item-title">
                          阻抗:
                        </div>
                        <el-row>
                          <el-col :span="12">
                            <el-input :disabled="!isEditWireParam" size="small" style="width: 85%;" placeholder="请输入内容" v-model="wireParam.atrialImpedance"></el-input>&nbsp;Ω&nbsp;
                          </el-col>
                        </el-row>
                      </div>
                      <el-checkbox :disabled="!isEditWireParam" v-model="wireParam.checkedRightVentricular" class="el-check-pinpai" label="右心室导线" @change="changeLead"></el-checkbox>
                      <div class="lead-right" v-if="wireParam.checkedRightVentricular">
                      <div class="item-title">植入部位:</div>
                        <el-radio-group :disabled="!isEditWireParam" v-model="wireParam.rightImplantPosition">
                          <el-radio :label="0">右室心尖部</el-radio>
                          <el-radio :label="1">右室间隔部</el-radio>
                          <el-radio :label="2">希氏束区域</el-radio>
                          <el-radio :label="3">左束支区域</el-radio>
                        </el-radio-group>
                      <div class="item-title">导线型号:</div>
                      <el-input :disabled="!isEditWireParam" size="small" placeholder="请输入导线型号" v-model="wireParam.rightWireModel"></el-input>
                      <div class="item-title">起搏阈值:</div>
                      <el-row>
                        <el-col :span="12">
                          <el-input :disabled="!isEditWireParam" size="small" style="width: 85%;" placeholder="请输入内容" v-model="wireParam.rightPacingThresholdV"></el-input>&nbsp;V@&nbsp;
                        </el-col>
                        <el-col :span="12">
                          <el-input :disabled="!isEditWireParam" size="small" style="width: 85%;" placeholder="请输入内容" v-model="wireParam.rightPacingThresholdMs"></el-input>&nbsp;ms&nbsp;
                        </el-col>
                      </el-row>
                      <div class="item-title">R波高度:</div>
                      <el-row>
                        <el-col :span="12">
                          <el-input :disabled="!isEditWireParam" size="small" style="width: 85%;" placeholder="请输入内容" v-model="wireParam.rightRWaveAltitude"></el-input>&nbsp;mV&nbsp;
                        </el-col>
                      </el-row>
                      <div class="item-title">阻抗:</div>
                        <el-row>
                          <el-col :span="12">
                            <el-input :disabled="!isEditWireParam" size="small" style="width: 85%;" placeholder="请输入内容" v-model="wireParam.rightImpedance"></el-input>&nbsp;Ω&nbsp;
                          </el-col>
                        </el-row>
                      <div class="item-title">高压除颤阻抗:</div>
                        <el-row>
                          <el-col :span="12">
                            <el-input :disabled="!isEditWireParam" size="small" style="width: 85%;" placeholder="请输入内容" v-model="wireParam.rightHighVoltageImpedance"></el-input>&nbsp;Ω&nbsp;
                          </el-col>
                        </el-row>
                      </div>
                      <el-checkbox :disabled="!isEditWireParam" v-model="wireParam.checkedLeftVentricular" class="el-check-pinpai" label="左心室导线" @change="changeLead"></el-checkbox>
                      <div class="lead-left" v-if="wireParam.checkedLeftVentricular">
                        <div class="item-title">植入部位:</div>
                        <el-radio-group :disabled="!isEditWireParam" v-model="wireParam.leftImplantPosition" @change="changeLeadSite">
                          <el-radio :label="0">侧后静脉</el-radio>
                          <el-radio :label="1">侧静脉</el-radio>
                          <el-radio :label="2">其他</el-radio>
                          <el-input
                            class="el-checkbox-input-left"
                            size="mini"
                            placeholder="请输入内容"
                            v-show="wireParam.leftImplantPosition === 2"
                            v-model="wireParam.leftOtherPosition"
                            :disabled="!isEditWireParam"
                            minlength="2"
                            maxlength="20">
                          </el-input>
                        </el-radio-group>
                        <div class="item-title">导线型号:</div>
                        <el-input :precision="2" :disabled="!isEditWireParam" size="small" placeholder="请输入导线型号" v-model="wireParam.leftWireModel"></el-input>
                        <div class="item-title">起搏阈值:</div>
                        <el-row>
                          <el-col :span="12">
                            <el-input :disabled="!isEditWireParam" size="small" style="width: 85%;" placeholder="请输入内容" v-model="wireParam.leftPacingThresholdV"></el-input>&nbsp;V@&nbsp;
                          </el-col>
                          <el-col :span="12">
                            <el-input :disabled="!isEditWireParam" size="small" style="width: 85%;" placeholder="请输入内容" v-model="wireParam.leftPacingThresholdMs"></el-input>&nbsp;ms&nbsp;
                          </el-col>
                        </el-row>
                        <div class="item-title">R波高度:</div>
                        <el-row>
                          <el-col :span="12">
                            <el-input :disabled="!isEditWireParam" size="small" style="width: 85%;" placeholder="请输入内容" v-model="wireParam.leftRWaveAltitude"></el-input>&nbsp;mV&nbsp;
                          </el-col>
                        </el-row>
                        <div class="item-title">阻抗:</div>
                        <el-row>
                          <el-col :span="12">
                            <el-input :disabled="!isEditWireParam" size="small" style="width: 85%;" placeholder="请输入内容" v-model="wireParam.leftImpedance"></el-input>&nbsp;Ω&nbsp;
                          </el-col>
                        </el-row>
                      </div>
                    </el-form-item>
                  </el-form>
                </div>
              </div>
              <!--    起搏器参数      -->
              <div class="content-part">
                <div class="content-title">
                  <div class="label">
                    7、起搏器参数设置
                    <div style="display: inline-block">
                      <i class="iconfontblack" type="text" size="mini" @click="isEditPacemakerQuery = true">&#xe600;</i>
                      <el-button class="edit-btn" v-if="isEditPacemakerQuery" type="text" size="mini" @click="handleSave(7)">保存</el-button>
                      <el-button class="edit-btn" v-if="isEditPacemakerQuery" type="text" size="mini" @click="handleCancel(7)">取消</el-button>
                    </div>
                  </div>
                </div>
                <div class="content-form">
                  <el-form :model="pacemakerQuery">
                    <el-form-item>
<!--                      <el-checkbox :disabled="!isEditPacemakerQuery" v-model="pacemakerQuery.checkedPacemakerModel" style="display: block" label="起搏模式" @change="changeQuery"></el-checkbox>-->
                      <div>起搏模式</div>
                      <el-radio-group :disabled="!isEditPacemakerQuery" v-model="pacemakerQuery.pacemakerModel" class="subPart adaptation_content--1">
                        <el-radio :label="1">AAI(R)</el-radio>
                        <el-radio :label="2">VVI(R)</el-radio>
                        <el-radio :label="3">DDD(R)</el-radio>
                      </el-radio-group>
<!--                      <el-checkbox :disabled="!isEditPacemakerQuery" v-model="pacemakerQuery.checkedBareLineHeartRate" label="基线频率" style="margin-right: 20px" @change="changeQuery"></el-checkbox>-->
                      <div>基线频率</div>
                      <div>
                        <el-input :disabled="!isEditPacemakerQuery" size="small" v-model="pacemakerQuery.bareLineHeartRate" style="width: 350px;" placeholder="请输入内容"></el-input>&nbsp;bpm
                      </div>
                      <span>
                        </span>
                    </el-form-item>
                  </el-form>
                </div>
              </div>
              <!--    不良事件      -->
              <div class="content-part">
                <div class="content-title">
                  <div class="label">
                    8、围术期不良事件
                    <div style="display: inline-block">
                      <i class="iconfontblack" type="text" size="mini" @click="isEditAdverseEvent = true">&#xe600;</i>
                      <el-button class="edit-btn" v-if="isEditAdverseEvent" type="text" size="mini" @click="handleSave(8)">保存</el-button>
                      <el-button class="edit-btn" v-if="isEditAdverseEvent" type="text" size="mini" @click="handleCancel(8)">取消</el-button>
                    </div>
                  </div>
                </div>
                <div class="content-form">
                  <el-form  style="margin-top: 15px" :model="adverseEvent">
                    <el-form-item style="margin-bottom: 0">
                      <el-radio-group :disabled="!isEditAdverseEvent" v-model="adverseEvent.hasEvent" @change="changeEvent">
                        <el-radio :label="0">无</el-radio>
                        <br>
                        <el-radio :label="1">有</el-radio>
                      </el-radio-group>
                    </el-form-item>
                    <el-form-item v-if="adverseEvent.hasEvent === 1">
                      <el-checkbox-group :disabled="!isEditAdverseEvent" v-model="adverseEvent.events" class="adaptation_content--1 events">
                        <el-checkbox :label="1" name="type">气胸</el-checkbox>
                        <el-checkbox :label="2" name="type">血胸</el-checkbox>
                        <el-checkbox :label="3" name="type">血气胸</el-checkbox>
                        <el-checkbox :label="4" name="type">导线穿孔</el-checkbox>
                        <el-checkbox :label="5" name="type">导线脱落</el-checkbox>
                        <el-checkbox :label="6" name="type">感染</el-checkbox>
                        <el-checkbox :label="7" name="type">导线重置</el-checkbox>
                        <el-checkbox :label="8" name="type">血肿</el-checkbox>
                        <el-checkbox :label="9" name="type" @change="adverseEventOther">其他</el-checkbox>
                        <el-input :disabled="!isEditAdverseEvent" v-if="adverseEvent.events.indexOf(9) !== -1" size="small" v-model="adverseEvent.otherEvent" style="width: 150px;" placeholder=""></el-input>
                      </el-checkbox-group>
                    </el-form-item>
                  </el-form>
                </div>
              </div>
            </comp-part>
          </div>
          <!-- <comp-part title="诊断证明书">
            <div class="certificate-diagnosis" id="part3" v-for="item in 6" :key="item">
              <el-carousel type="card" :interval="0" height="300px">
                <el-carousel-item v-for="item in 6" :key="item">
                   <el-image :src="src"></el-image>
                </el-carousel-item>
              </el-carousel>
              <el-image
                style="width: 200px; height: 150px;margin-right: 20px;"
                :src="src"
                :preview-src-list="[src]">
              </el-image>
              <el-button class="img-btn" type="primary" plain>查看大图</el-button>
            </div>
          </comp-part> -->
          <div id="part4">
            <comp-part title="随访记录">
<!--              <el-button type="primary" style="margin-bottom: 20px" >展示全部随访记录</el-button>-->
              <img v-if="!isOpen" style="width: 15px; height: 15px; margin-bottom: 10px;" src="https://f-u-system.oss-cn-beijing.aliyuncs.com/open.svg" @click="onOverFollowUpRecords" alt="">
              <img v-else style="width: 15px; height: 15px; margin-bottom: 10px;" src="https://f-u-system.oss-cn-beijing.aliyuncs.com/close.svg" @click="onOverFollowUpRecords" alt="">
              <el-table :data="followUpTableData">
              <el-table-column
                  align="center"
                  label="序号"
                  type="index"
                  width="50">
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="recordName"
                  width="140"
                  label="随访周期">
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="visitTime"
                  width="250"
                  label="随访日期">
                    <template slot-scope="scope">
                      <span v-if="scope.row.type === 0">{{!scope.row.visitStartTime ? '-': moment.unix(scope.row.visitStartTime / 1000).format('Y-MM-DD')}}~{{!scope.row.visitEndTime ? '-' : moment.unix(scope.row.visitEndTime / 1000).format('Y-MM-DD')}}</span>
                      <span v-if="scope.row.type === 1">{{!scope.row.finishTime ? '-': moment.unix(scope.row.finishTime / 1000).format('Y-MM-DD')}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="visitTime"
                  label="随访完成时间">
                    <template slot-scope="scope">
                      <span v-if="!scope.row.finishTime">-</span>
                      <span v-else>{{moment.unix(scope.row.finishTime / 1000).format('Y-MM-DD')}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  label="随访状态">
                    <el-tag type="success" >已完成</el-tag>
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="isVisit"
                  label="到院复诊情况">
                    <template slot-scope="scope">
                      <span>{{scope.row.isVisit ? '已到院复诊':'未到院复诊'}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="remark"
                  label="注意事项">
                    <template slot-scope="scope">
                      <span v-if="!scope.row.remark">-</span>
                      <div v-else>
                        <el-popover
                          placement="top-start"
                          title=""
                          width="230"
                          trigger="hover"
                          data-html="true"
                        >
                          <div style="width:200px;">{{scope.row.remark}}</div>
                          <div slot="reference" class="show-visit-records">
                            <span >{{scope.row.remark}}</span>
                          </div>
                        </el-popover>
                      </div>
                    </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  width="140"
                  label="操作">
                  <template slot-scope="scope">
                    <el-button type="text" @click="handleInfo(scope.row.id)">随访信息</el-button>
                    <el-button type="text" @click="handleFile(scope.row.id)">文件</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <el-table v-if="isShowOverFollowUpRecords" style="margin-top: 20px;" :data="overFollowUpTableData">
              <el-table-column
                  align="center"
                  label="序号"
                  type="index"
                  width="50">
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="name"
                  width="140"
                  label="随访周期">
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="visitTime"
                  width="250"
                  label="随访日期">
                    <template slot-scope="scope">
                      <span>{{!scope.row.visitStartTime ? '-': moment.unix(scope.row.visitStartTime / 1000).format('Y-MM-DD')}}~{{!scope.row.visitEndTime ? '-' : moment.unix(scope.row.visitEndTime / 1000).format('Y-MM-DD')}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="visitTime"
                  label="随访完成时间"
                  width="120">
                    <template slot-scope="scope">
                      <span v-if="!scope.row.visitTime">-</span>
                      <span v-else>{{moment.unix(scope.row.visitTime / 1000).format('Y-MM-DD')}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="status"
                  label="随访状态">
                    <template slot-scope="scope">
                      <el-tag type="info" v-if="scope.row.status === 0">未开始</el-tag>
                      <el-tag v-if="scope.row.status === 1">进行中</el-tag>
                      <el-tag type="success" v-if="scope.row.status === 2">已完成</el-tag>
                      <el-tag type="danger" v-if="scope.row.status === 3">已超窗</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="isVisit"
                  label="到院复诊情况"
                  width="140">
                    <template slot-scope="scope">
                      <span>{{scope.row.isVisit ? '已到院复诊':'未到院复诊'}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="remark"
                  label="注意事项">
                    <template slot-scope="scope">
                      <span v-if="!scope.row.remark">-</span>
                      <div v-else>
                        <el-popover
                          placement="top-start"
                          title=""
                          width="230"
                          trigger="hover"
                          data-html="true"
                        >
                          <div style="width:200px;">{{scope.row.remark}}</div>
                          <div slot="reference" class="show-visit-records">
                            <span >{{scope.row.remark}}</span>
                          </div>
                        </el-popover>
                      </div>
                    </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  width="140"
                  label="操作">
                  <span>-</span>
                </el-table-column>
              </el-table>
            </comp-part>
          </div>
          <div id="part5">
            <comp-part title="心理量表" style="height: 800px">
              <div style="height: 800px">
                <el-scrollbar style="height: 100%">
                  <el-table :data="psyScaleData">
                  <el-table-column
                      align="center"
                      label="序号"
                      type="index"
                      width="50">
                    </el-table-column>
                    <el-table-column
                      align="center"
                      prop="name"
                      label="量表名称">
                    </el-table-column>
                    <el-table-column
                      align="center"
                      prop="createAt"
                      label="填表日期">
                        <template slot-scope="scope">
                          <span>{{moment.unix(scope.row.createAt / 1000).format('Y-MM-DD HH:mm:ss')}}</span>
                        </template >
                    </el-table-column>
                    <el-table-column
                      align="center"
                      width="400"
                      label="操作">
                      <template slot-scope="scope">
                        <el-button type="text" @click="handleDetail(scope.row.id)">查看详情</el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                  <el-pagination
                    @current-change="handleCurrentChange"
                    :current-page.sync="psyScaleCurrentPage"
                    :page-size="20"
                    layout="total, prev, pager, next"
                    :total="psyScaleData.length">
                  </el-pagination>
                </el-scrollbar>
              </div>
            </comp-part>
          </div>
        </div>
      </el-scrollbar>
    </el-card>
    <comp-info-dialog
      @sure="handleSure"
      :visible.sync="infoFlagVisible"
      :infoFlagData="infoFlagData"
      :recordId="recordId">
    </comp-info-dialog>
    <psy-scale-dialog :visible.sync="scaleFlag" :psyScaleDetailData="psyScaleDetailData"></psy-scale-dialog>
    <file-dialog :visible.sync="fileDialogVisible" :fileList="fileList"></file-dialog>
  </div>
</template>

<script>
import CompPart from '@/components/Part'
import CompInfoDialog from './components/infoDialog.vue'
import PsyScaleDialog from './components/psyScaleDialog.vue'
import FileDialog from './components/fileDialog.vue'
import BaseLineInfo from './components/baseLineInfo.vue'
import { regionList } from '@/utils/enums'
import {
  getBaselineInfo,
  editBaselineInfo,
  getPsycScale,
  getPsycScaleDetail,
  getFollowUpRecords,
  getOverFollowUpRecords,
  getImplant,
  saveImplant,
  getfileList,
  getFollowUpForm
} from '@/service/module/followup'
import regionOptions from '@/utils/region'

export default {
  moment: null,
  components: {
    CompPart,
    CompInfoDialog,
    PsyScaleDialog,
    FileDialog,
    BaseLineInfo
  },
  data () {
    return {
      psyScaleCurrentPage: 1,
      requireItem: {
        implantDate: [
          { required: true, message: '请选择植入日期', trigger: 'blur' }
        ],
        operator: [
          { required: true, message: '请输⼊术者', trigger: 'blur' }
        ],
        hospitalName: [
          { required: true, message: '请输⼊医院', trigger: 'blur' }
        ]
      },
      // 1、植入信息
      implantInfo: {
        implantDate: '',
        implantDoctor: '',
        implantHospital: ''
      },
      // 2、植入部位
      implantPosition: -1,
      // 3、适应证
      indications: {
        indicationsSymptom: [],
        bradycardiaRadio: [],
        arrhythmiaRadio: null,
        otherIndications: ''
      },
      // 4、类型
      ciedType: {
        ciedTypeRadio: -1,
        otherCiedType: ''
      },
      // 5、品牌
      ciedBrand: {
        yapeitext: '',
        bailiduotext: '',
        boketext: '',
        qitatext: '',
        model: null,
        key: null
      },
      ciedBrandSelect: [ // 美敦力设备列表
        { children: [] }
      ],
      // 6、导线
      wireParam: {
        checkedAtrial: false,
        checkedLeftVentricular: false,
        checkedRightVentricular: false,
        // 心房导线参数
        atrialImplantPosition: -1,
        atrialWireModel: '',
        atrialPacingThresholdV: '',
        atrialPacingThresholdMs: '',
        atrialPWaveAltitude: '',
        atrialImpedance: '',
        // 右心室导线参数
        rightImplantPosition: -1,
        rightWireModel: '',
        rightPacingThresholdV: '',
        rightPacingThresholdMs: '',
        rightRWaveAltitude: '',
        rightHighVoltageImpedance: '',
        rightImpedance: '',
        // 左心室导线参数
        leftOtherPosition: '',
        leftImplantPosition: -1,
        leftWireModel: '',
        leftPacingThresholdV: '',
        leftPacingThresholdMs: '',
        leftRWaveAltitude: '',
        leftImpedance: ''
      },
      // 7、起搏器参数
      pacemakerQuery: {
        checkedPacemakerModel: false,
        bareLineHeartRate: '',
        checkedBareLineHeartRate: false,
        pacemakerModel: 0
      },
      // 8、不良事件
      adverseEvent: {
        hasEvent: 0,
        events: []
      },
      edit: {
        baseEdit: true,
        siteEdit: true,
        adaptationEdit: true,
        typeEdit: true,
        brandEdit: true,
        leadEdit: true,
        queryEdit: true,
        eventEdit: true
      },
      followUpTableData: [ // 随访记录
      ],
      overFollowUpTableData: [], // 逾期随访记录
      psyScaleData: [], // 心理量表数据
      psyScaleDetailData: {},
      followUpTotalPage: 0, // 总条数
      followUpCurrentPage: 1, // 当前条数
      followUpPageSize: 20, // 每页条数
      // src: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
      infoFlagVisible: false, // 随访信息弹窗是否展示
      fileDialogVisible: false,
      scaleFlag: false,
      baseLineInfoList: [
        {
          title: '患者姓名',
          key: 'name',
          value: '',
          rules: /[^\u4e00-\u9fa5a-zA-Z0-9·]/ig,
          tips: '请输入患者姓名',
          minlength: 2,
          maxlength: 20,
          type: 'edit',
          contentType: 'text',
          textEdit: true
        },
        {
          title: '年龄',
          key: 'age',
          value: null,
          contentType:
          'text',
          textEdit: true
        },
        { title: '性别', key: 'gender', value: '', type: 'edit', contentType: 'select', options: regionList, textEdit: true },
        { title: '常驻地', key: 'formAddress', contentType: 'cascader', value: [], options: regionOptions, type: 'edit', textEdit: true },
        {
          title: '病案号',
          key: 'recordNo',
          tips: '请输入病案号',
          minlength: 6,
          maxlength: 7,
          value: null,
          type: 'edit',
          contentType: 'text',
          textEdit: true
        },
        { title: '注册手机号', key: 'phone', value: null, contentType: 'text', textEdit: true },
        {
          title: '身份证号',
          key: 'certificateNo',
          tips: '请输入身份证号',
          value: null,
          // type: 'edit',
          contentType: 'text',
          textEdit: true
        },
        { title: '文化程度', key: 'educationBackground', tips: '请输入文化程度', value: '', type: 'edit', contentType: 'select', options: [], textEdit: true },
        {
          title: '基础疾病',
          key: 'underlyingDiseases',
          value: [],
          otherDiseases: '',
          span: 4,
          type: 'edit',
          contentType: 'checkbox',
          options: [
            { code: 5, text: '冠心病', type: 1 },
            { code: 6, text: '心肌病', type: 1 },
            { code: 7, text: '瓣膜病', type: 1 },
            { code: 8, text: '先天性心脏病', type: 1 },
            { code: 9, text: '肺心病', type: 1 },
            { code: 1, text: '高血压', type: 1 },
            { code: 4, text: '其他', type: 2 }
          ],
          textEdit: true
        }
      ],
      isShowOverFollowUpRecords: false, // 是否显示逾期随访记录
      isEditImplantInfo: false, // 1植入信息是否可编辑
      isEditImplantPosition: false, // 2植入部位是否可编辑
      isEditIndications: false, // 3适应症是否可编辑
      isEditType: false, // 4类型是否可编辑
      isEditBrand: false, // 5品牌是否可编辑
      isEditWireParam: false, // 6导线是否可编辑
      isEditPacemakerQuery: false, // 7起搏器参数设置是否可编辑
      isEditAdverseEvent: false, // 8围术期不良事件是否可编辑

      infoFlagData: {},
      fileList: [], // 文件列表

      eduBackgroundOptions: [],
      recordId: null,
      isOpen: false
    }
  },
  mounted () {
    this.moment = require('moment')
    // if (!this.$route.query.planId) { return }

    Promise.all([
      this.getBaselineInfo(),
      this.getImplantInfo(this.$route.query.patientId),
      this.getPsycScale(1, 20),
      this.getFollowUpRecords()
    ]).then(res => {
      console.log(res)
    })
  },
  methods: {
    adverseEventOther (val) {
      this.adverseEvent.otherEvent = ''
    },
    async getImplantInfo (patientId) {
      try {
        const res = await getImplant(patientId)
        /**
         * 初始值判定赋值
         * */
        this.ciedBrandSelect = res.data.ciedBrandSelect
        this.implantInfo = {
          implantDate: res.data.implantDate,
          operator: res.data.operator,
          hospitalName: res.data.hospitalName
        }
        if (res.data.ciedKind) {
          this.ciedType = res.data.ciedKind
        }
        if (res.data.pacemakerParam) {
          this.pacemakerQuery = res.data.pacemakerParam
        }
        if (res.data.leadParam) {
          this.wireParam = res.data.leadParam
        }
        if (res.data.adverseEvent) {
          this.adverseEvent = res.data.adverseEvent
        }
        if (res.data.adaptationDisease) {
          this.indications = res.data.adaptationDisease
          if (typeof this.indications.bradycardiaRadio === 'number') {
            this.indications.bradycardiaRadio = [this.indications.bradycardiaRadio]
          }
        }
        if (res.data.id) {
          this.id = res.data.id
        }
        if (res.data.ciedBrand) {
          this.ciedBrand = res.data.ciedBrand
        }
        if (res.data.ciedImplantSite) {
          this.implantPosition = res.data.ciedImplantSite
        }
        if (res.data.ciedBrand && typeof (res.data.ciedBrand.model) === 'string') {
          const obj = {
            code: 0,
            title: this.ruleForm.ciedBrand.model
          }
          this.ciedBrandSelect.push(obj)
        }
      } catch (e) {
        console.log('err;', e)
      }
    },
    changeCiedBrand (val) {
      this.ciedBrand.key = val
      switch (val) {
        case 0:
          this.ciedBrand.yapeitext = ''
          this.ciedBrand.bailiduotext = ''
          this.ciedBrand.boketext = ''
          this.ciedBrand.qitatext = ''
          break
        case 1:
          this.ciedBrand.bailiduotext = ''
          this.ciedBrand.boketext = ''
          this.ciedBrand.qitatext = ''
          this.ciedBrand.model = null
          break
        case 2:
          this.ciedBrand.yapeitext = ''
          this.ciedBrand.boketext = ''
          this.ciedBrand.qitatext = ''
          this.ciedBrand.model = null
          break
        case 3:
          this.ciedBrand.yapeitext = ''
          this.ciedBrand.bailiduotext = ''
          this.ciedBrand.qitatext = ''
          this.ciedBrand.model = null
          break
        case 4:
          this.ciedBrand.yapeitext = ''
          this.ciedBrand.boketext = ''
          this.ciedBrand.bailiduotext = ''
          this.ciedBrand.model = null
          break
      }
    },
    changeCiedType (val) {
      if (val !== 6) {
        this.ciedType.otherCiedType = ''
      }
    },
    changeAdaptation (val) {
      if (val.indexOf(0) === -1) {
        this.indications.bradycardiaRadio = []
      }
      if (val.indexOf(3) === -1) {
        this.indications.otherIndications = ''
      }
      if (val.indexOf(2) === -1) {
        this.indications.arrhythmiaRadio = null
      }
    },
    changeLead () {
      if (!this.wireParam.checkedAtrial) {
        this.wireParam.atrialImplantPosition = -1
        this.wireParam.atrialWireModel = ''
        this.wireParam.atrialPacingThresholdV = ''
        this.wireParam.atrialPacingThresholdMs = ''
        this.wireParam.atrialPWaveAltitude = ''
        this.wireParam.atrialImpedance = ''
      }
      if (!this.wireParam.checkedRightVentricular) {
        this.wireParam.rightImplantPosition = -1
        this.wireParam.rightWireModel = ''
        this.wireParam.rightPacingThresholdV = ''
        this.wireParam.rightPacingThresholdMs = ''
        this.wireParam.rightRWaveAltitude = ''
        this.wireParam.rightHighVoltageImpedance = ''
        this.wireParam.rightImpedance = ''
      }
      if (!this.wireParam.checkedLeftVentricular) {
        this.wireParam.leftOtherPosition = ''
        this.wireParam.leftImplantPosition = -1
        this.wireParam.leftWireModel = ''
        this.wireParam.leftPacingThresholdV = ''
        this.wireParam.leftPacingThresholdMs = ''
        this.wireParam.leftRWaveAltitude = ''
        this.wireParam.leftImpedance = ''
      }
    },
    changeLeadSite (val) {
      if (val !== 2) {
        this.wireParam.leftOtherPosition = ''
      }
    },
    changeQuery () {
      if (!this.pacemakerQuery.checkedPacemakerModel) {
        this.pacemakerQuery.model = 0
      }
      if (!this.pacemakerQuery.checkedBareLineHeartRate) {
        this.pacemakerQuery.bareLineHeartRate = ''
      }
    },
    changeEvent () {
      if (this.adverseEvent.hasEvent === 0) {
        this.adverseEvent.events = []
      }
    },
    handleInfo (id) {
      this.infoFlagVisible = true
      getFollowUpForm({
        recordId: id
      }).then(res => {
        if (res.data) {
          this.infoFlagData = res.data.content
          console.log('ooooo:', this.infoFlagData)
          this.recordId = id
        }
      })
    },
    handleFile (id) {
      this.fileDialogVisible = true
      getfileList(id).then(res => {
        if (res.data) {
          this.fileList = res.data
        }
      })
    },
    handleDetail (id) {
      this.scaleFlag = true
      getPsycScaleDetail(id).then(res => {
        if (res.data) {
          this.psyScaleDetailData = res.data
        }
      })
    },
    getBaselineInfo () {
      getBaselineInfo(this.$route.query.patientId).then(res => {
        if (res.data) {
          this.data = res.data
          for (var i of this.baseLineInfoList) {
          // 多选
            if (i.contentType === 'checkbox') {
            // 如果是基础疾病
              if (i.key === 'underlyingDiseases') {
                this.$set(i, 'options', res.data.underlyingDiseaseSelect)
                this.$set(i, 'value', this.toNumberList(res.data.underlyingDiseases))
                this.$set(i, 'otherDiseases', res.data.otherDiseases)
              }
            } else
            // 单选
            if (i.contentType === 'select') {
            // 如果是文化程度
              if (i.key === 'educationBackground') {
                this.$set(i, 'options',
                  [{ code: 0, text: '请选择' },
                    { code: 1, text: '初中及以下' },
                    { code: 2, text: '高中' },
                    { code: 3, text: '大学' },
                    { code: 4, text: '研究生及以上' }])
              }
              this.$set(i, 'value', this.data[i.key])
            } else if (i.contentType === 'cascader') {
            // 如果是常住地
              var formAddress = []
              if (res.data.province) {
                formAddress[0] = res.data.province
              }
              if (res.data.city) {
                formAddress[1] = res.data.city
              }
              if (res.data.district) {
                formAddress[2] = res.data.district
              }
              this.$set(i, 'value', formAddress)
            } else {
              this.$set(i, 'value', this.data[i.key])
            }
          }
        }
      })
    },
    toNumberList (data) {
      var a = []
      data.forEach(e => {
        a.push(Number(e))
      })
      console.log('lll', a)
      return a
    },
    getPsycScale (pageNum, pageSize) {
      getPsycScale({
        patientId: this.$route.query.patientId,
        pageNum: pageNum,
        pageSize: pageSize
      }).then(res => {
        if (res.data) {
          this.psyScaleData = res.data.results
        }
      })
    },
    getFollowUpRecords () {
      getFollowUpRecords({
        patientId: this.$route.query.patientId
      }).then(res => {
        if (res.data) {
          this.followUpTableData = res.data
        }
      })
    },
    getOverFollowUpRecords () {
      getOverFollowUpRecords({
        patientId: this.$route.query.patientId,
        status: 3
      }).then(res => {
        if (res.data) {
          this.overFollowUpTableData = res.data
        }
      })
    },
    editData (data) {
      var obj = {}
      this.$set(obj, data.key, data.value)
      this.$set(obj, 'patientId', this.$route.query.patientId)
      if (data.otherDiseases) {
        this.$set(obj, 'otherDiseases', data.otherDiseases)
      }
      if (obj.formAddress) {
        obj.province = obj.formAddress[0]
        obj.city = obj.formAddress[1]
        obj.district = obj.formAddress[2]
      }
      editBaselineInfo(obj).then(res => {
        console.log('qqqqqqqq:', obj)
        if (res.code === 200) {
          this.$message.success(res.message)
          this.getBaselineInfo()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    cancel () {
      this.getBaselineInfo()
    },
    onOverFollowUpRecords () {
      this.isOpen = !this.isOpen
      this.isShowOverFollowUpRecords = !this.isShowOverFollowUpRecords
      if (this.isOpen) {
        this.getOverFollowUpRecords()
      }
    },
    async handleSave (type) {
      this.editBaseInfo(type, 1)
    },
    editBaseInfo (type, val) {
      switch (type) {
        case 1:
          if (val === 1) {
            this.$refs.requireItem.validate(async valid => {
              if (valid) {
                this.isEditImplantInfo = false
                this.saveImplant(1)
              }
            })
          } else {
            this.isEditImplantInfo = false
            this.getImplantInfo(this.$route.query.patientId)
          }
          break
        case 2:
          if (val === 1) {
            this.isEditImplantPosition = false
            this.saveImplant(1)
          } else {
            this.isEditImplantPosition = false
            this.getImplantInfo(this.$route.query.patientId)
          }
          break
        case 3:
          if (val === 1) {
            this.isEditIndications = false
            this.saveImplant(1)
          } else {
            this.isEditIndications = false
            this.getImplantInfo(this.$route.query.patientId)
          }
          break
        case 4:
          if (val === 1) {
            this.isEditType = false
            this.saveImplant(1)
          } else {
            this.isEditType = false
            this.getImplantInfo(this.$route.query.patientId)
          }
          break
        case 5:
          if (val === 1) {
            this.isEditBrand = false
            this.saveImplant(1)
          } else {
            this.isEditBrand = false
            this.getImplantInfo(this.$route.query.patientId)
          }
          break
        case 6:
          if (val === 1) {
            this.isEditWireParam = false
            this.saveImplant(1)
          } else {
            this.isEditWireParam = false
            this.getImplantInfo(this.$route.query.patientId)
          }
          break
        case 7:
          if (val === 1) {
            this.isEditPacemakerQuery = false
            this.saveImplant(1)
          } else {
            this.isEditPacemakerQuery = false
            this.getImplantInfo(this.$route.query.patientId)
          }
          break
        case 8:
          if (val === 1) {
            this.isEditAdverseEvent = false
            this.saveImplant(1)
          } else {
            this.isEditAdverseEvent = false
            this.getImplantInfo(this.$route.query.patientId)
          }
          break
      }
    },
    handleCancel (val) {
      this.editBaseInfo(val, 0)
    },
    async saveImplant (val) {
      if (val === 1) {
        try {
          const params = {
            id: this.id,
            ...this.implantInfo,
            adaptationDisease: this.indications,
            ciedBrand: this.ciedBrand,
            leadParam: this.wireParam,
            ciedImplantSite: this.implantPosition,
            ciedKind: this.ciedType,
            pacemakerParam: this.pacemakerQuery,
            adverseEvent: this.adverseEvent
          }
          const res = await saveImplant(params)
          if (res.code === 200) {
            this.$message({
              message: '植入信息保存成功',
              type: 'success'
            })
            this.$emit('update:visible', false)
          } else {
            this.$message({
              message: '保存失败',
              type: 'error'
            })
          }
        } catch (e) {
          this.$message({
            message: '系统错误',
            type: 'error'
          })
        }
      }
    },
    handleSure (isShow) {
      this.infoFlagVisible = isShow
      this.$router.push({
        name: 'patientFiles',
        query: {
          planId: this.$route.query.planId,
          userId: this.$route.query.userId
        }
      })
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
      this.getPsycScale(val, 20)
    }

  }
}
</script>

<style scoped lang="scss">
.patient-files {
  height: 100%;
  display: flex;
  position:absolute;
  top:0px;
  left:0px;
  .files-menu {
    background: #F5F7FA;
    margin-right: 0px;
    .box-card {
      width: 115px;
      background: #F5F7FA;
      border:0;
      .menu-item {
        display: flex;
        width: 100%;
        height: 60px;
        margin-bottom: 2px;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        cursor: pointer;
        font-family: 'Helvetica Neue';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #51565D;
        &:last-of-type {
          border-bottom: none;
        }
      }
    }
  }
  ::v-deep .el-card__body {
    height: 100%;
  }
  .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 200px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }

}
.el-descriptions-item__label {
  line-height: 28px;
}
.certificate-diagnosis {
  position: relative;
  display: inline-block;
  width: 200px;
  height: 150px;
  margin-right: 20px;
}
.img-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 9999;
  // margin-left: -100px;
  // margin-top: -75px;
  transform: translate(-50%,-50%);
}
.content-card{
  .files-container {
        .content-part {
      .content-title {
        display: flex;
        align-items: center;
        .label {
          font-size: 16px;
          font-weight: bold;
          margin-right: 15px;
        }
      }
      .content-form {
        padding-left: 40px;
        .fix-form__width {
          width: 80%;
        }
        .el-radio {
          margin-bottom: 15px;
        }
        .el-checkbox-input {
          width: 430px;
        }
        .editor {
          text-align: center;
          display: inline-block;
          width: 90px;
          line-height: 1;
          border-bottom: 1px solid #000;
          font-size: 16px;
        }
        .el-checkbox-input-left {
          width: 180px;
          /*height: 10px;*/
          vertical-align: initial;
        }
        .el-check-pinpai {
          width: 100%;
        }
        .adaptation_content--1 {
          border: 1px solid #EBEEF5;
          display: flex;
          align-items: center;
          width: 450px;
          padding: 16px;
          ::v-deep .el-radio {
            margin-bottom: 0;
          }
        }
        .subPart {
          display: block;
          padding-left: 30px;
          width: 350px;
        }
        .events {
          width: 600px;
          display: flex;
          flex-wrap: wrap;
        }
        .lead-atrial {
          padding: 16px;
          width: 510px;
          border: 1px solid #EBEEF5;
        }
        .lead-right {
          padding: 16px;
          border: 1px solid #EBEEF5;
          width: 510px;
        }
        .lead-left {
          padding: 16px;
          border: 1px solid #EBEEF5;
          width: 510px;
        }
      }
    }
    .part1 {
      ::v-deep .el-radio {
        margin-bottom: 15px;
      }
      ::v-deep .el-radio-group {
        display: block;
        margin-left: 20px;
        font-size: inherit;
      }
    }
  }
}
.edit-btn {
    margin-left: 10px;
}
.show-visit-records {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.iconfontblack {
  cursor: pointer;
}
.el-pagination {
  float: right;
}
</style>
