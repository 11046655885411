export const regionList = [ // 性别映射表
  {
    code: '1',
    text: '男'
  }, {
    code: '2',
    text: '女'
  }
]

export const assessResult = { // 问卷结果映射表
  1: ['正常', '轻度焦虑', '中度焦虑', '重度焦虑'],
  2: ['正常', '轻度抑郁', '中度抑郁', '重度抑郁'],
  3: ['您不易焦虑，易表现出安定的状态。', '', '您较易焦虑，易表现出烦躁、不安静和神经过敏。'],
  4: ['您的抑郁程度较弱，生活态度乐观积极，充满活力，心境愉快。', '', '您的抑郁程度较强，生活缺乏足够的兴趣，缺乏运动活力。']
}

export const followUpStatus = { // 随访状态映射表
  0: '未开始',
  1: '进行中',
  2: '完成',
  3: '超窗'
}

// export const eduLevelList = [ // 文化程度列表
//   {
//     code: '0',
//     text: '请选择'
//   }, {
//     code: '1',
//     text: '初中及以下'
//   }, {
//     code: '2',
//     text: '高中'
//   }, {
//     code: '3',
//     text: '大学'
//   }, {
//     code: '4',
//     text: '研究生及以上'
//   }
// ]
