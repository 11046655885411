<template>
  <el-dialog
    :title="psyScaleDetailData.name"
    width="600px"
    center
    :visible="visible"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :lock-scroll="true"
    @close="cancel"
  >
  <div class="subject-content">
    <div v-for="(item,index) in psyScaleDetailData.data" :key="index" class="subject-item">
      <p style="margin-bottom:10px;">{{++index}}、{{item.label}}</p>
      <el-radio-group v-model="item.choose + 1">
        <el-radio
          style="margin-bottom:15px;"
          v-for="(i) in item.opts"
          :key="i.id"
          :label="i.id">
          {{i.text}}
        </el-radio>
      </el-radio-group>
    </div>
    <p class="p-achievement">测试评分：{{psyScaleDetailData.score}}分</p>
    <p>测试结果：{{psyScaleDetailData.resultText}}</p>
  </div>
  </el-dialog>
</template>

<script>
import { assessResult } from '@/utils/enums'
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    psyScaleDetailData: {
      type: Object
    }
  },
  data () {
    return {
      assessId: null,
      resultText: '',
      score: 0
    }
  },
  watch: {
    psyScaleDetailData (val) {
      if (val) {
        this.assessId = this.psyScaleDetailData.id
        this.score = this.psyScaleDetailData.score
        if (this.assessId === 1) {
          if (this.score < 50) {
            this.resultText = assessResult[this.assessId][0]
          } else if (this.score >= 50 && this.score <= 59) {
            this.resultText = assessResult[this.assessId][1]
          } else if (this.score >= 60 && this.score <= 69) {
            this.resultText = assessResult[this.assessId][2]
          } else if (this.score > 69) {
            this.resultText = assessResult[this.assessId][3]
          }
        }

        if (this.assessId === 2) {
          if (this.score < 53) {
            this.resultText = assessResult[this.assessId][0]
          } else if (this.score >= 53 && this.score <= 62) {
            this.resultText = assessResult[this.assessId][1]
          } else if (this.score >= 63 && this.score <= 72) {
            this.resultText = assessResult[this.assessId][2]
          } else if (this.score > 73) {
            this.resultText = assessResult[this.assessId][3]
          }
        }

        if (this.assessId === 3) {
          if (this.score < 20) {
            this.resultText = assessResult[this.assessId][0]
          } else if (this.score >= 20 && this.score <= 30) {
            this.resultText = assessResult[this.assessId][1]
          } else if (this.score > 30) {
            this.resultText = assessResult[this.assessId][2]
          }
        }

        if (this.assessId === 4) {
          if (this.score < 26) {
            this.resultText = assessResult[this.assessId][0]
          } else if (this.score >= 26 && this.score <= 39) {
            this.resultText = assessResult[this.assessId][1]
          } else if (this.score > 39) {
            this.resultText = assessResult[this.assessId][2]
          }
        }
        this.$set(this.psyScaleDetailData, 'resultText', this.resultText)
      }
    }
  },
  methods: {
    cancel () {
      this.$emit('update:visible', false)
    }
  }
}
</script>

<style scoped lang="scss">
  .subject-content {
    width: 400px;
    margin: 0 auto;
    p {
      font-size: 16px;
      font-weight: 500;
    }
  }
  .subject-item {
    // height: 50px;
  }
  .p-achievement {
    margin-top: 20px;
  }
</style>
