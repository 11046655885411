<template>
  <div class="baseline-info-box">
    <el-descriptions :column="4" :labelStyle="LS" >
        <el-descriptions-item :span="item.span ? item.span : 1" v-for="(item,index) in list" :key="index" :label="item.title">
            <span v-if="item.contentType === 'text' && item.textEdit">{{item.value}}</span>
            <el-input
            class="text-int"
            size="mini"
            v-if="item.contentType === 'text' && !item.textEdit"
            v-model="item.value"
            placeholder="请输入内容"
            :minlength="item.minlength"
            :maxlength="item.maxlength"
            :onkeyup="
            (function () {
              item.value=item.value.replace(item.rules,'')
            })()">
            </el-input>
            <el-select class="text-int" size="mini" v-model="item.value" :disabled="item.textEdit" v-if="item.contentType === 'select' && item.options" placeholder="请选择">
              <el-option
                v-for="i in item.options"
                :key="i.code"
                :label="i.text"
                :value="i.code">
              </el-option>
            </el-select>
            <el-cascader
              v-if="item.contentType === 'cascader'"
              class="text-int" size="mini"
              v-model="item.value"
              :disabled="item.textEdit"
              placeholder="请选择"
              :options="item.options"
              filterable
            />
            <el-checkbox-group v-if="item.contentType === 'checkbox'" :disabled="item.textEdit" v-model="item.value" @change="(val) => {changeCheckbox(val, item)}">
              <el-checkbox v-for="(i) in item.options" :key="i.code" :label="i.code">{{i.text}}
                <el-input style="width: 130px" v-if="i.type === 2 && item.value.indexOf(4) !== -1" :disabled="item.textEdit" size="mini" v-model="item.otherDiseases"></el-input>
              </el-checkbox>
            </el-checkbox-group>
            <div style="display: inline-block">
              <i class="iconfontblack" v-if="item.type === 'edit' && item.textEdit" type="text" size="mini" @click="item.textEdit = false">&#xe600;</i>
              <!-- <el-button class="edit-btn" v-if="item.type === 'edit' && item.textEdit" type="text" size="mini" @click="item.textEdit = false">编辑</el-button> -->
              <el-button class="edit-btn" v-if="item.type === 'edit' && !item.textEdit" type="text" size="mini" @click="handleSave(item)">保存</el-button>
              <el-button class="edit-btn" v-if="item.type === 'edit' && !item.textEdit" type="text" size="mini" @click="handleCancel(item)">取消</el-button>
            </div>
        </el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script>
export default {
  data () {
    return {
      checkList: [],
      selectValue: null,
      LS: {
        'line-height': '25px'
      }
    }
  },
  props: {
    list: {
      type: Array
    }
  },
  methods: {
    handleSave (item) {
      item.textEdit = true
      if (!item.value) {
        this.$message.error(item.tips)
        item.textEdit = false
      } else if (item.key === 'recordNo') {
        if (item.value.length <= 6) {
          this.$message.error('请输入正确的病案号')
          item.textEdit = false
          return false
        }
      } else if (item.key === 'underlyingDiseases') {
        this.$emit('editData', item)
      } else {
        item.textEdit = true
        this.$emit('editData', item)
      }
      // else if (item.key === 'certificateNo') {
      //   var reg = /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}[0-9Xx]$)/
      //   if (reg.test(item.value) === false) {
      //     this.$message.error('请输入正确的身份证号码')
      //     item.textEdit = false
      //     return false
      //   } else {
      //     return true
      //   }
      // }
    },
    changeCheckbox (val, item) {
      if (val.indexOf(4) === -1) {
        item.otherDiseases = ''
      }
    },
    handleCancel (item) {
      item.textEdit = true
      this.$emit('cancel')
    }
  }

}
</script>

<style scoped >
.text {
    line-height: 40px;
}
.edit-btn {
    margin-left: 10px;
}
.text-int {
  width: 130px;
}
::v-deep .el-descriptions-item__container {
    display: inline-block;
}
::v-deep .el-checkbox-group {
    display: inline-block;
}
  .iconfontblack {
    cursor: pointer;
  }
</style>
