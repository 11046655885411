<template>
  <el-dialog
    title="随访信息"
    width="800px"
    height="500px"
    :visible="visible"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :lock-scroll="true"
    :show-close="false"
  >
    <div class="content">
      <el-scrollbar ref="wrapper">
        <div class="content-part">
          <div class="content-title">
            <div class="label">1、随访日期</div>
          </div>
          <div class="content-form">
            <el-form style="margin-top: 15px">
              <el-form-item label="日期">
                <el-date-picker
                  v-model="implantDate"
                  class="fix-form__width"
                  type="date"
                  format="yyyy-MM-dd"
                  value-format="timestamp"
                  placeholder="选择日期">
                </el-date-picker>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <!--    电池状态   -->
        <div class="content-part">
          <div class="content-title">
            <div class="label">2、电池状态</div>
          </div>
          <div class="content-form">
            <el-form style="margin-top: 15px" :model="batteryStatus">
              <el-form-item>
                <el-radio-group v-model="batteryStatus.status" @change="changeBattery">
                  <el-radio :label="0">OK</el-radio>
                  <div v-if="batteryStatus.status === 0" style="display: inline-block; font-size: 14px;">
                    &nbsp;&nbsp;&nbsp;
                    预计寿命:
                    <el-input size="small" v-model="batteryStatus.expectedLife" style="display: inline-block; width: 100px"></el-input> 年
                  </div>
                  <br>
                  <el-radio :label="1">电池电量下降</el-radio>
                  <br>
                  <el-radio :label="2">电池耗竭</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <!--    起搏器参数      -->
        <div class="content-part">
          <div class="content-title">
            <div class="label">3、起搏参数</div>
          </div>
          <div class="content-form">
            <el-form :model="pacemakerQuery">
              <el-form-item>
<!--                <el-checkbox v-model="pacemakerQuery.checkedPacemakerModel" style="display: block" label="起搏模式" @change="changeQuery"></el-checkbox>-->
                <div>起搏模式</div>
                <el-radio-group v-model="pacemakerQuery.pacemakerModel" @change="changePacemakerModel" class="subPart adaptation_content--1">
                  <el-radio :label="1">AAI(R)</el-radio>
                  <el-radio :label="2">VVI(R)</el-radio>
                  <el-radio :label="3">DDD(R)</el-radio>
                </el-radio-group>
<!--                <el-checkbox v-model="pacemakerQuery.checkedBareLineHeartRate" label="基线频率" style="margin-right: 20px" @change="changeQuery"></el-checkbox>-->
                <div style="display: inline-block; margin-right: 20px">基线频率</div>
                <div style="display: inline-block; width: 200px;">
                  <el-input v-model="pacemakerQuery.bareLineHeartRate" size="mini" style="display: inline-block; width: 80%;"></el-input> bpm
                </div>
                <br>
<!--                <el-checkbox v-model="pacemakerQuery.checkedAtrialPacingPercent" label="心房起搏百分比" style="margin-right: 20px" @change="changeQuery"></el-checkbox>-->
                <div v-if="pacemakerQuery.pacemakerModel === 1 || pacemakerQuery.pacemakerModel === 3">
                  <div style="display: inline-block; margin-right: 20px">心房起搏百分比</div>
                  <div style="display: inline-block; width: 200px;">
                    <el-input v-model="pacemakerQuery.atrialPacingPercent" size="mini" style="display: inline-block; width: 80%;"></el-input> %
                  </div>
                  <br>
                </div>
                <div v-if="pacemakerQuery.pacemakerModel === 2 || pacemakerQuery.pacemakerModel === 3">
                  <div style="display: inline-block; margin-right: 20px">心室起搏百分比</div>
                  <div style="display: inline-block; width: 200px;">
                    <el-input v-model="pacemakerQuery.ventricularPacingPercent" size="mini" style="display: inline-block; width: 80%;"></el-input> %
                  </div>
                  <br>
                </div>
<!--                <el-checkbox v-model="pacemakerQuery.checkedATAF" label="AT/AF负荷" style="margin-right: 20px" @change="changeQuery"></el-checkbox>-->
                <div style="display: inline-block; margin-right: 20px">AT/AF负荷</div>
                <div style="display: inline-block; width: 200px;">
                  <el-input v-model="pacemakerQuery.atAf" size="mini" style="display: inline-block; width: 80%;"></el-input> %
                </div>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <!--    导线      -->
        <div class="content-part">
          <div class="content-title">
            <div class="label">4、电极导线参数</div>
          </div>
          <div class="content-form">
            <el-form :model="wireParam">
              <el-form-item>
                <el-checkbox v-model="wireParam.checkedAtrial" class="el-check-pinpai" label="心房导线" @change="changeLead"></el-checkbox>
                <div class="lead-atrial" v-if="wireParam.checkedAtrial">
                  <div class="item-title">
                    起搏阈值:
                  </div>
                  <el-row>
                    <el-col :span="12">
                      <el-input size="small" style="width: 85%;" placeholder="请输入内容" v-model="wireParam.atrialPacingThresholdV"></el-input>&nbsp;V@&nbsp;
                    </el-col>
                    <el-col :span="12">
                      <el-input size="small" style="width: 85%;" placeholder="请输入内容" v-model="wireParam.atrialPacingThresholdMs"></el-input>&nbsp;ms
                    </el-col>
                  </el-row>
                  <div class="item-title">
                    感知:
                  </div>
                  <el-row>
                    <el-col :span="12">
                      <el-input size="small" style="width: 85%;" placeholder="请输入内容" v-model="wireParam.atrialPerception"></el-input>&nbsp;mV&nbsp;
                    </el-col>
                  </el-row>
                  <div class="item-title">
                    阻抗:
                  </div>
                  <el-row>
                    <el-col :span="12">
                      <el-input size="small" style="width: 85%;" placeholder="请输入内容" v-model="wireParam.atrialImpedance"></el-input>&nbsp;Ω&nbsp;
                    </el-col>
                  </el-row>
                </div>
                <el-checkbox v-model="wireParam.checkedRightVentricular" class="el-check-pinpai" label="右心室导线" @change="changeLead"></el-checkbox>
                <div class="lead-right" v-if="wireParam.checkedRightVentricular">
                  <div class="item-title">起搏阈值:</div>
                  <el-row>
                    <el-col :span="12">
                      <el-input size="small" style="width: 85%;" placeholder="请输入内容" v-model="wireParam.rightPacingThresholdV"></el-input>&nbsp;V@&nbsp;
                    </el-col>
                    <el-col :span="12">
                      <el-input size="small" style="width: 85%;" placeholder="请输入内容" v-model="wireParam.rightPacingThresholdMs"></el-input>&nbsp;ms&nbsp;
                    </el-col>
                  </el-row>
                  <div class="item-title">感知:</div>
                  <el-row>
                    <el-col :span="12">
                      <el-input size="small" style="width: 85%;" placeholder="请输入内容" v-model="wireParam.rightPerception"></el-input>&nbsp;mV&nbsp;
                    </el-col>
                  </el-row>
                  <div class="item-title">阻抗:</div>
                  <el-row>
                    <el-col :span="12">
                      <el-input size="small" style="width: 85%;" placeholder="请输入内容" v-model="wireParam.rightImpedance"></el-input>&nbsp;Ω&nbsp;
                    </el-col>
                  </el-row>
                  <div class="item-title">高压除颤阻抗:</div>
                  <el-row>
                    <el-col :span="12">
                      <el-input size="small" style="width: 85%;" placeholder="请输入内容" v-model="wireParam.rightHighVoltageImpedance"></el-input>&nbsp;Ω&nbsp;
                    </el-col>
                  </el-row>
                </div>
                <el-checkbox v-model="wireParam.checkedLeftVentricular" class="el-check-pinpai" label="左心室导线" @change="changeLead"></el-checkbox>
                <div class="lead-left" v-if="wireParam.checkedLeftVentricular">
                  <div class="item-title">起搏阈值:</div>
                  <el-row>
                    <el-col :span="12">
                      <el-input size="small" style="width: 85%;" placeholder="请输入内容" v-model="wireParam.leftPacingThresholdV"></el-input>&nbsp;V@&nbsp;
                    </el-col>
                    <el-col :span="12">
                      <el-input size="small" style="width: 85%;" placeholder="请输入内容" v-model="wireParam.leftPacingThresholdMs"></el-input>&nbsp;ms&nbsp;
                    </el-col>
                  </el-row>
                  <div class="item-title">感知:</div>
                  <el-row>
                    <el-col :span="12">
                      <el-input size="small" style="width: 85%;" placeholder="请输入内容" v-model="wireParam.leftPerception"></el-input>&nbsp;mV&nbsp;
                    </el-col>
                  </el-row>
                  <div class="item-title">阻抗:</div>
                  <el-row>
                    <el-col :span="12">
                      <el-input size="small" style="width: 85%;" placeholder="请输入内容" v-model="wireParam.leftImpedance"></el-input>&nbsp;Ω&nbsp;
                    </el-col>
                  </el-row>
                </div>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <!--    不良事件      -->
        <div class="content-part">
          <div class="content-title">
            <div class="label">5、随访期不良事件</div>
          </div>
          <div class="content-form">
            <el-form style="margin-top: 15px" :model="adverseEvent">
              <el-form-item style="margin-bottom: 0">
                <el-radio-group v-model="adverseEvent.hasEvent" @change="changeEvent">
                  <el-radio :label="0">无</el-radio>
                  <el-radio :label="1">有</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item v-if="adverseEvent.hasEvent === 1">
                <el-checkbox-group class="adaptation_content--1 events" v-model="adverseEvent.events" @change="changeEventOther">
                  <el-checkbox :label="1" name="type">导线穿孔</el-checkbox>
                  <el-checkbox :label="2" name="type">导线脱位</el-checkbox>
                  <el-checkbox :label="3" name="type">感染</el-checkbox>
                  <el-checkbox :label="4" name="type">导线重置</el-checkbox>
                  <el-checkbox :label="5" name="type">血肿</el-checkbox>
                  <br>
                  <el-checkbox :label="6" name="type">其他</el-checkbox>
                  <el-input v-if="adverseEvent.events.indexOf(6) !== -1" v-model="adverseEvent.otherEvent" style="display: inline-block; width: 100px; margin-left: 10px" size="mini"></el-input>
                </el-checkbox-group>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </el-scrollbar>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="cancel">取 消</el-button>
      <el-button type="primary" @click="confirm">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import {
  saveFollowUpForm
} from '@/service/module/followup'
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    infoFlagData: {
      type: Object
    },
    recordId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      // 1、植入日期
      implantDate: '',
      // 2、电池状态
      batteryStatus: {
        status: -1,
        expectedLife: ''
      },
      // 3、起搏器参数
      pacemakerQuery: {
        checkedPacemakerModel: false,
        pacemakerModel: 0,
        checkedBareLineHeartRate: false,
        bareLineHeartRate: '',
        checkedAtrialPacingPercent: false,
        atrialPacingPercent: '',
        ventricularPacingPercent: '',
        checkedATAF: false,
        atAf: ''
      },
      // 4、导线
      wireParam: {
        checkedAtrial: false,
        checkedLeftVentricular: false,
        checkedRightVentricular: false,
        atrialWire: {},
        // 心房导线参数
        atrialPacingThresholdV: '',
        atrialPacingThresholdMs: '',
        atrialPerception: '',
        atrialImpedance: '',
        // 右心室导线参数
        rightPacingThresholdV: '',
        rightPacingThresholdMs: '',
        rightPerception: '',
        rightHighVoltageImpedance: '',
        rightImpedance: '',
        // 左心室导线参数
        leftPacingThresholdV: '',
        leftPacingThresholdMs: '',
        leftPerception: '',
        leftImpedance: ''
      },

      // 5、不良事件
      adverseEvent: {
        hasEvent: -1,
        events: [],
        otherEvent: ''
      }
    }
  },
  watch: {
    infoFlagData (val) {
      console.log('ohye:', val)
      if (val) {
        this.implantDate = val.implantDate
        this.batteryStatus = val.batteryStatus
        this.pacemakerQuery = val.pacemakerQuery
        this.wireParam = val.wireParam
        this.adverseEvent = val.adverseEvent
      }
    }
  },
  methods: {
    confirm () {
      saveFollowUpForm({
        recordId: this.recordId,
        content: {
          implantDate: this.implantDate,
          batteryStatus: this.batteryStatus,
          pacemakerQuery: this.pacemakerQuery,
          wireParam: this.wireParam,
          adverseEvent: this.adverseEvent
        }

      }).then(res => {
        if (res.code === 200) {
          this.$message.success(res.message)
          this.$emit('sure', false)
        }
      })
    },
    cancel () {
      this.reset()
      this.$emit('update:visible', false)
    },
    changeBattery (val) {
      if (val !== 0) {
        this.batteryStatus.expectedLife = ''
      }
    },
    changeQuery () {
      if (!this.pacemakerQuery.checkedPacemakerModel) {
        this.pacemakerQuery.pacemakerModel = 0
      }
      if (!this.pacemakerQuery.checkedBareLineHeartRate) {
        this.pacemakerQuery.bareLineHeartRate = ''
      }
      if (!this.pacemakerQuery.checkedAtrialPacingPercent) {
        this.pacemakerQuery.atrialPacingPercent = ''
      }
      if (!this.pacemakerQuery.checkedATAF) {
        this.pacemakerQuery.atAf = ''
      }
    },
    changeLead () {
      if (!this.wireParam.checkedAtrial) {
        this.wireParam.atrialPacingThresholdV = ''
        this.wireParam.atrialPacingThresholdMs = ''
        this.wireParam.atrialPerception = ''
        this.wireParam.atrialImpedance = ''
      }
      if (!this.wireParam.checkedRightVentricular) {
        this.wireParam.rightVentriculaLead = {}
        this.wireParam.rightPacingThresholdV = ''
        this.wireParam.rightPacingThresholdMs = ''
        this.wireParam.rightPerception = ''
        this.wireParam.rightHighVoltageImpedance = ''
        this.wireParam.rightImpedance = ''
      }
      if (!this.wireParam.checkedLeftVentricular) {
        this.wireParam.leftPacingThresholdV = ''
        this.wireParam.leftPacingThresholdMs = ''
        this.wireParam.leftPerception = ''
        this.wireParam.leftImpedance = ''
      }
    },
    changePacemakerModel (val) {
      if (val === 1) {
        this.pacemakerQuery.ventricularPacingPercent = ''
      }
      if (val === 2) {
        this.pacemakerQuery.atrialPacingPercent = ''
      }
    },
    changeEvent () {
      if (this.adverseEvent.hasEvent !== 1) {
        this.adverseEvent.events = []
        this.adverseEvent.otherEvent = ''
      } else {
        this.$refs.wrapper.wrap.scrollTop = this.$refs.wrapper.wrap.scrollHeight
      }
    },
    changeEventOther () {
      if (this.adverseEvent.events.indexOf(6) === -1) {
        this.adverseEvent.otherEvent = ''
      }
    },
    reset () {
      this.implantDate = ''
      this.batteryStatus = {
        status: -1,
        expectedLife: ''
      }
      this.pacemakerQuery = {
        checkedPacemakerModel: false,
        pacemakerModel: 0,
        checkedBareLineHeartRate: false,
        bareLineHeartRate: '',
        checkedAtrialPacingPercent: false,
        atrialPacingPercent: '',
        ventricularPacingPercent: '',
        checkedATAF: false,
        atAf: ''
      }
      this.wireParam = {
        checkedAtrial: false,
        checkedLeftVentricular: false,
        checkedRightVentricular: false,
        atrialWire: {},
        // 心房导线参数
        atrialPacingThresholdV: '',
        atrialPacingThresholdMs: '',
        atrialPerception: '',
        atrialImpedance: '',
        // 右心室导线参数
        rightPacingThresholdV: '',
        rightPacingThresholdMs: '',
        rightPerception: '',
        rightHighVoltageImpedance: '',
        rightImpedance: '',
        // 左心室导线参数
        leftPacingThresholdV: '',
        leftPacingThresholdMs: '',
        leftPerception: '',
        leftImpedance: ''
      }
      this.adverseEvent = {
        hasEvent: -1,
        events: [],
        otherEvent: ''
      }

      this.$emit('sure', {
        implantDate: this.implantDate,
        batteryStatus: this.batteryStatus,
        pacemakerQuery: this.pacemakerQuery,
        wireParam: this.wireParam,
        adverseEvent: this.adverseEvent
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .content {
    height: 400px;
    .content-part {
      .content-title {
        display: flex;
        align-items: center;
        .label {
          font-size: 16px;
          font-weight: bold;
          margin-right: 15px;
          width: 150px;
        }
      }
      .content-form {
        padding-left: 80px;
        .fix-form__width {
          width: 80%;
        }
        .el-radio {
          margin-bottom: 15px;
        }
        .el-checkbox-input {
          width: 430px;
        }
        .editor {
          text-align: center;
          display: inline-block;
          width: 90px;
          line-height: 1;
          border-bottom: 1px solid #000;
          font-size: 16px;
        }
        .el-checkbox-input-left {
          width: 180px;
          /*height: 10px;*/
          vertical-align: initial;
        }
        .el-check-pinpai {
          width: 100%;
        }
        .adaptation_content--1 {
          border: 1px solid #EBEEF5;
          display: flex;
          align-items: center;
          width: 450px;
          padding: 16px;
          ::v-deep .el-radio {
            margin-bottom: 0;
          }
        }
        .subPart {
          display: block;
          padding-left: 30px;
          width: 350px;
        }
        .events {
          width: 550px;
          display: flex;
          flex-wrap: wrap;
        }
        .lead-atrial {
          padding: 16px;
          width: 510px;
          border: 1px solid #EBEEF5;
        }
        .lead-right {
          padding: 16px;
          border: 1px solid #EBEEF5;
          width: 510px;
        }
        .lead-left {
          padding: 16px;
          border: 1px solid #EBEEF5;
          width: 510px;
        }
      }
    }
    .part1 {
      ::v-deep .el-radio {
        margin-bottom: 15px;
      }
      ::v-deep .el-radio-group {
        display: block;
        margin-left: 20px;
        font-size: inherit;
      }
    }
    ::v-deep .el-scrollbar .el-scrollbar__wrap{
      padding-bottom: 13px;
      max-height: 445px;
    }
  }
</style>
