<template>
  <div class="file-content">
    <el-drawer
        title="文件列表"
        :visible="visible"
        @close="cancel">
        <div
          v-for=" (item,index) in fileList"
          :key="index"
          style="margin-bottom: 20px;">
          <a
              style="padding-left: 20px"
              :href="item.url"
              target="_blank">
              {{item.filename}}
          </a>
        </div>
    </el-drawer>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    tabPosition: {
      type: String,
      default: 'left'
    },
    fileList: {
      type: Array
    }
  },
  data () {
    return {
    }
  },
  methods: {
    cancel () {
      this.$emit('update:visible', false)
    }
  }
}
</script>

<style scoped lang="scss">
</style>
